.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.items {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 200px;
}

.item-list {
  width: 240px;
}

.item-list li {
  list-style: none;
}

.item-list li article {
  background: #d0edff;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #eaf7ff;
  box-shadow: 2px 9px 14px 0px rgb(85 85 85 / 41%);
}

.item-list li article img {
  width: 100%;
}

.item-list li article a {
  width: 100%;
  background: #6eb37e;
  border: 3px solid #6eb37e;
  color: white;
  padding: 12px 0;
  border: none;
  cursor: pointer;
  display: inline-flex;
  text-decoration: none;
  justify-content: center;
}

.success {
  color: white;
}
